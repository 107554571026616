<template>
  <ApprovalCard
    :request="request"
    :is-approving="isApproving"
    :is-rejecting="isRejecting"
    @approve="onApprove"
    @reject="onReject"
  >
    <template #content>
      <VLine :label="$t('app.attestations')">
        <template #value>
          <div class="cursor-pointer" @click="onClickRedirectToAttestation">
            {{ getText(request.attestation?.texts, "name") }}
          </div>
        </template>
      </VLine>

      <VLine :label="$t('app.files')">
        <template #value>
          <FileItems :files="request?.files" />
        </template>
      </VLine>
    </template>

    <template #form>
      <VComment
        v-model="instructor_comments"
        class="col-span-2"
        :label="$t('app.instructor_comment')"
      />
    </template>
  </ApprovalCard>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import ApprovalCard from "@/views/teaching/approvals/ApprovalCard";
import VLine from "@/components/VLine";
import VComment from "@/components/inputs/VComment";
import FileItems from "@/components/FileItems";

export default {
  components: {
    ApprovalCard,
    VLine,
    VComment,
    FileItems
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Composables
    const { isRequesting: isApproving, request: approve } = useRequest();
    const { isRequesting: isRejecting, request: reject } = useRequest();
    const { getText } = useTexts();

    // Data
    const instructor_comments = ref(props.request.instructor_comments);

    // Methods
    const onClickRedirectToAttestation = () => {
      router.push({
        name: "teaching-attestations-update",
        params: {
          id: props.request.attestation.id
        }
      });
    };

    const onApprove = async () => {
      await approve({
        endpoint: "teaching.attestations.submission.submit",
        pathParams: { id: props.request.id },
        data: {
          instructor_comments: instructor_comments.value,
          decision: "Approved"
        }
      });

      context.emit("refresh");
    };

    const onReject = async () => {
      await reject({
        endpoint: "teaching.attestations.submission.submit",
        pathParams: { id: props.request.id },
        data: {
          instructor_comments: instructor_comments.value,
          decision: "Rejected"
        }
      });

      context.emit("refresh");
    };

    return {
      instructor_comments,
      onApprove,
      onReject,
      onClickRedirectToAttestation,
      // useTexts
      getText,
      // useRequest
      isApproving,
      isRejecting
    };
  }
};
</script>
